/* eslint no-use-before-define: 0 */  // --> OFF
/* eslint-disable no-console */
require('url-search-params-polyfill');
const pubsub = require('raptor-pubsub');
const {get} = require('@ebay/retriever');
const searchInstaller = require('../utils/searchInstaller');
const SUPPORT_TRACKING_NAMES_APPT_DATE_TIME = ['VAS_VI', 'SHOPACTIONS'];

const googlemapConfig = {
    "key": "AIzaSyAjBJ4a_gkScDpMEvW20N2_QhTogSXJ2R4",
    "enabled": true
}

const dialogDismiss = (channelId, isSpoke) => {
    pubsub
        .channel(channelId)
        .emit((isSpoke ? 'VAS_DISMISS_SPOKE' : 'VAS_DISMISS_INTERSTITIAL'));
};

const handleCtaClick =  (channelId, params) => {
    if (params) {
        pubsub.channel(channelId).emit('VAS_CLOSE_SPOKE', params);
        // pubsub.channel(channelId).emit('tracking', tracking);
    } else {
        console.debug("handleCtaClick::VAS_CLOSE_SPOKE was NOT emitted"); 
    }
};

const removeUrlSid= (url) => {
    if (!url) {
        return '';
    }
    const regexForTrksidParam = new RegExp("[?&]_trksid=([^&]+).*$");
    const oldSid = url.match(regexForTrksidParam);
    if (oldSid && oldSid.length > 1) { // replace if found match
        const sidParam = `_trksid=${oldSid[1]}`;
        return url.replace(`&${sidParam}`, '').replace(sidParam, '');
    }
    return url;
};

const getCommonVasSpAnc = (rootEl) => {
    let link = document.getElementById('vas-sp-nav');
    if (!link) {
      link = document.createElement('a');
      link.id = 'vas-sp-nav';
      link.setAttribute('style', 'display:none');
      if (rootEl) {
          rootEl.append(link);
      } else {
          document.body.append(link);
      }
    }
    return link;
};

const handleDefaultOperationWithoutUrl = (action, ctx, res) => {
    const selectedParams = getSelectedParams(action, ctx, res);
    handleCtaClick(ctx.channelId, selectedParams);
};

const handleOperationWithNameWithoutUrl = (action, ctx) => {
    const name = get(action, 'name', '');
    if (name) {
        if(name === 'RETURN_TO_INSTALLATION') {
            ctx.setState({
                appointmentModel: null
            });
            setTimeout(
                () => {
                  refreshKeyboardTrap(ctx.channelId);
                  setScreenDefaultFocus(ctx.targetElId, true)
                },
                100
            );
        }
    } 
};


const doRedirection = (destinationUrl, trksid, rootEl, ctx, name) => {
    if (ctx && name) {
        ctx.setState({
            isBusy: true,
            processingActionName: name,
        })
    }

    if (!destinationUrl) {
        if (ctx) {
            ctx.setState({
                isBusy: false,
                showLoading: false
            })
        }
        return;
    }

    const resultUrl = removeUrlSid(destinationUrl);
    const link = getCommonVasSpAnc(rootEl);
    if (link) {
        link.setAttribute('tabindex', -1);
        link.setAttribute('href', resultUrl);
        if (trksid) {
            link.setAttribute('_sp', trksid);
        }
        link.click();
    }
};

const getAppointmentState= (input) => {
    const appointmentModel = get(input, 'model', {});
    appointmentModel.channelId = input.channelId;
    return {
        appointmentModel,
        appointmentSelectedDate: null,
        appointmentSelectedSlot: null,
        vasSelection: null
    };
};

const getVehicleState = ({ model, channelId}) => {
    const vehicleModules = model || {};
    vehicleModules.channelId = channelId;
    return {
        vehicleModules,
        appointmentModel: null
    };
};

const updateState= (input) => {
    const model = get(input, 'model', {});
    const layerModules = (model.VAS_SPOKE || model.VAS_INTERSTITIAL_MODULE) || {};
    const installerList = get(layerModules, 'vasForm', []);
    const previousSelection = get(model, 'selectedInstaller.addonId');
    const selectedInstaller = installerList.find(installer =>
        (installer.selected === 'true' || previousSelection === installer.paramValue)) || installerList[0];
    const installerScreenCTA = get(model, 'VAS_CTA', {});
    const isSpoke = (get(layerModules, 'uxComponentName', '') === 'VAS_SPOKE_V2');

    let radius, postalCode;
    if (model.VAS_RADIUS_SEARCH) {
        const formEntries = get(model, 'VAS_RADIUS_SEARCH.radiusSearchForm.form.entries', []);
        const postRadiusValues = searchInstaller.extractPostCodeRadiusValues(formEntries);
        radius = postRadiusValues.radius;
        postalCode = postRadiusValues.postalCode;
    } else {
        radius = get(layerModules, 'radius.currentOption.value', '');
        postalCode = get(layerModules, 'postalCode.paramValue');
    }

    return {
        radius: radius,
        postalCode: postalCode,
        selectedInstaller,
        mapCenterIcon: layerModules.mapCenterIcon,
        installerList,
        sortV2 : layerModules.sortV2,
        showNotice: (installerList.length === 0),
        showLoading: false,
        showMap: false,
        showLocation: false,
        showSort: false,
        showInstaller: false,
        statusMessage: model.VAS_STATUS_MESSAGE,
        installerScreenCTA: installerScreenCTA,
        isBusy: false,
        appointmentModel: null,
        processingActionName: '',
        loadingScreen: false,
        appointmentSelectedDate: null,
        appointmentSelectedSlot: null,
        selectedVehicle: null,
        vehicleModules: null,
        isSpoke: isSpoke
    };
};

const getInstallerUrl = ({params}, {radius, postalCode, model, csrfToken}) => {
    const installerListQueryParams = new URLSearchParams();
    const layerModules = (model.VAS_SPOKE || model.VAS_INTERSTITIAL_MODULE) || {};
    const quantity = params && params.quantity;

    installerListQueryParams.set('postalCode', postalCode);
    installerListQueryParams.set('radius', radius);
    installerListQueryParams.set('moduleGroups', 'VAS_DETAILS');
    installerListQueryParams.set('supportedVasTypes', 'INSTALLATION');
    installerListQueryParams.set('itemId', (params && params.itemId));
    installerListQueryParams.set('sourceModule', get(params, 'sourceModule', '') || layerModules.uxComponentName);
    installerListQueryParams.set('srt', csrfToken);
    
    const _ru = get(params, 'ru', '');
    if(_ru) {
        installerListQueryParams.set('ru', _ru);
    }
    
    const _src = get(params, 'src', '');
    if(_src) {
        installerListQueryParams.set('src', _src);
    }

    if (quantity) {
        installerListQueryParams.set('quantity', quantity);
    }

    return `/vas/api/getModules?${installerListQueryParams.toString()}`;
};

const mapConfig= () => googlemapConfig;

const track = (channelId, trackingObj) => {
    pubsub.channel(channelId).emit('tracking', trackingObj)
};

const appendTrackingProperty = (trackingList, property, value) => {
    if (Array.isArray(trackingList) && trackingList.length > 0) {
        trackingList.forEach((entry) => {
            if (entry && entry.eventProperty) {
                entry.eventProperty[property] = value;
            }
        });
    }
    return trackingList;
};

const checkOnDateTimeTrackingProperty = (trackingList, action, property, dateTimeValue) => {
    const name = get(action, 'name', ''); 
    let _trackingList = trackingList;
    if (SUPPORT_TRACKING_NAMES_APPT_DATE_TIME.includes(name) && dateTimeValue) {
        _trackingList = appendTrackingProperty(trackingList, property, dateTimeValue);
    }
    return _trackingList;
};

const getBase64EncodedString= (jsonInp) => {
    try{
        return btoa(JSON.stringify(jsonInp));
    } catch(e){
        // In case of decoding issue, vasselection string become empty
    }
    return "";
};

const getDefaultZipcode = (ctx) => get(ctx.input, 'model.VAS_CTA.sections.0.dataItems.1.action.params.userPostalCode', '');
const getDefaultShipToCountry = (ctx) => get(ctx.input, 'model.VAS_CTA.sections.0.dataItems.1.action.params.userCountryCode', '');

const getSelectedParams =  (action ,ctx, res) => {
    const name = get(action, 'name', '');
    const params = get(action, 'params', {});
    const selectedInstaller =  get(ctx, 'state.selectedInstaller', {});
    const addonType = selectedInstaller.paramKey || params.vasType || '';
    const addonValue = params.itemId || '';
    const quantity = params.quantity || '1';
    const vasValues = selectedInstaller.vasValues || {};
    const selectedParams = {
        itemId: addonValue,
        addonMeta: vasValues,
        type: addonType,
        value: selectedInstaller.paramValue || '',
        vasSelectionDisplayParams: [
            {
                'serviceType': addonType,
                'serviceValue': addonValue,
                'quantity': quantity, 
                'defaultShippingInfo': {
                    'shippingZipCode': getDefaultZipcode(ctx),
                    'shipToCountryCode': getDefaultShipToCountry(ctx)
                },
                'shippingInfo': {
                    'shippingZipCode': vasValues.postalCode,
                    'shipToCountryCode': vasValues.country
                }
            }
        ]
    };

// Decline usecase
    if(params.decline === 'true') {
        const postalCode =  params.userPostalCode || '';
        const country = params.userCountryCode || '';
        selectedParams.defaultPostalCode = postalCode;
        selectedParams.defaultCountryCode = country;
        selectedParams.vasSelectionDisplayParams[0].shippingInfo = {
            'shippingZipCode': postalCode,
            'shipToCountryCode': country
        };
    }

    // This is only for Add Date and Time CTA in Spoke TI layer
    if (name  === 'VAS_VI' || name === 'VASMP_VEHICLE_SELECTION') {
        if (ctx.state.vasSelection && ctx.state.appointmentSelectedDate && ctx.state.appointmentSelectedSlot) {
            ctx.state.vasSelection.date = ctx.state.appointmentSelectedDate;
            selectedParams.vasSelection = [getBase64EncodedString(ctx.state.vasSelection)];
            selectedParams.vasSelectionParams = [ctx.state.vasSelection];
        }
    } else if(name === 'VAS_CONFIRM_VEHICLE') {
        const callToActions =  get(res, 'VEHICLE_CARD_MODULE.callToActions.0', {});
        const fits = get(callToActions, 'action.params.fits', '');
        const selectedData = get(ctx, 'state.vehicleModules.VAS_DATA.dataItems', []);
        if(fits && selectedData.length) {
            selectedData[0].fits = fits;
            selectedParams.vasSelectionDisplayParams[0].vehicleAction = callToActions;
            selectedParams.vasSelectionDisplayParams[0].vehicleTokens = { fits };
        }
        selectedParams.vasSelectionParams = selectedData;
    } else {
        const vasSelectionParamsFromEntry =  get(selectedInstaller, 'additionalParamKeyValues', {});
        if (!vasSelectionParamsFromEntry.__isEmpty) {
            if(params && params.decline === "true") {
                vasSelectionParamsFromEntry.decline = 'true';
            }
            selectedParams.vasSelectionParams = [vasSelectionParamsFromEntry];
        }
    }

    // This is only for decline CTA
    if(params && params.decline === "true") {
        selectedParams.addonMeta = params;
        selectedParams.value = '-1';
        ctx.state.selectedInstaller = null;
    }

    return selectedParams;
};

const preHandleOperationWithUrl= (action, ctx) => {
    const name = get(action, 'name','');
    if (name) {
        if (name === 'VASMP_APPOINTMENT' || name === 'VASMP_VEHICLE_SELECTION') {
            ctx.setState({
                isBusy: true,
                processingActionName: name,
                loadingScreen: true
              });
        } else if (name === 'SHOPACTIONS') {
            ctx.setState({
                isBusy: true,
                processingActionName: name
              });
        }
    }
};

const updateInputParams = (action, ctx) => {
    const name = get(action, 'name', '');
    const params = get(action, 'params', {});
    const selectedInstaller =  get(ctx, 'state.selectedInstaller', {});
    const selectedVehicle = get(ctx, 'state.selectedVehicle', {});

    if (name && params) {
        if (name === 'VASMP_APPOINTMENT') {
            params.selectedServices = {[selectedInstaller.paramKey]:[selectedInstaller.paramValue]}
            const selectionInfoFromEntry = get(selectedInstaller, 'additionalParamKeyValues', {});
            if (!selectionInfoFromEntry.__isEmpty) {
                params.vasSelectionParams = [selectionInfoFromEntry];
            }
            /* params.selectedServices = {
                "INSTALLATION": ["260031609592"]
            };*/
            // Subsequent call expects supportedUxComponents as an Array
            if (params.supportedUxComponents && !Array.isArray(params.supportedUxComponents)) {
                params.supportedUxComponents = [params.supportedUxComponents];
            }
          
        } else if(name === 'VAS_CONFIRM_VEHICLE') {
            params.vasSelectionParams = getVehicleParams(ctx, selectedVehicle);
        } else if (name === 'SHOPACTIONS' || name === 'VAS_VI' || name === 'VASMP_VEHICLE_SELECTION') {
            if (ctx.state.vasSelection && ctx.state.appointmentSelectedDate && ctx.state.appointmentSelectedDate.length > 0 && ctx.state.appointmentSelectedSlot) {
                ctx.state.vasSelection.date = new Date(ctx.state.appointmentSelectedDate).toISOString();
                params.vasSelection = [getBase64EncodedString(ctx.state.vasSelection)];
                params.vasSelectionParams = [ctx.state.vasSelection];
            }
            
            // Subsequent call expects supportedUxComponents as an Array
            if (params.supportedUxComponents && !Array.isArray(params.supportedUxComponents)) {
                params.supportedUxComponents = [params.supportedUxComponents];
            }
            
            let selectedInstallerParamValue = selectedInstaller.paramValue;
            if (name === 'SHOPACTIONS') {

                if(selectedVehicle.vehicle) {
                    params.vasSelectionParams = getVehicleParams(ctx, selectedVehicle);
                }
                params.srt = getShopactionsCsrfToken(action, ctx);
                
                // this is to ensure the defaultActionUrl url in in defaultActionurl is an addonfe url 
                 if(params && params.decline === "true") {
                    selectedInstallerParamValue = '-1';
                }
            }
            if(selectedInstaller.paramKey && selectedInstallerParamValue) {
                params.selectedServices = {[selectedInstaller.paramKey]: [selectedInstallerParamValue]};
            }
        }
    }
    return params;
};

/**
 * Confirm Installer & Add Date & time CTA
 * @param action holds the url, name and param
 * @param callback handleAjaxResponse(res, err, action, ctx)
 * @param options handle timeout 
 */
const handleActionWithUrl = (action, callback, ctx, options = {}) => {
    preHandleOperationWithUrl(action, ctx);

    const timeout = options.timeout || 6000;
    const controller = new AbortController();
    const id = setTimeout(() => {
        controller.abort();
    }, timeout);

    const name = get(action, 'name', '');
    let URL = get(action, 'URL', '');
    const params = get(action, 'params', {});

    if (name && name === 'SHOPACTIONS') {
        const srt = get(params, 'srt', '');
        if (URL && !URL.endsWith('?')) {
            URL = `${URL}?`;
        }
        URL = `${URL}srt=${srt}`;
    }

    const bodyValue = JSON.stringify(params); 
    fetch(URL, {
        signal: controller.signal,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: bodyValue
    }).then(response => {
        if (!response.ok) {
            console.debug(`response is NOT ok, throwing error : ${response.statusText}`);
            throw response.statusText;
        }
        return response.json();
    }).then(data => {
        if (data) {
            callback(data, null, action, ctx);
        } else {
            console.debug('There has been a problem with your fetch operation: data is empty');
            throw new Error('There has been a problem with your fetch operation: data is empty') ;          
        }
    }).catch((error) => {
        console.debug(`There has been a problem with your fetch operation: ${error.message}`);
        callback(null, error, action, ctx);
    }).finally(() => {
        clearTimeout(id);
    });
};

const isActionHandleByName= (action) => {
    const name = get(action, 'name', '');
    return name && (name  === 'RETURN_TO_INSTALLATION');
};

const updateAppointmentState= (evtData, ctx) => {
    if (evtData) {
        /* ctx.state.appointmentSelectedDate = evtData.selectedDate;
        ctx.state.appointmentSelectedSlot = evtData.selectedSlot;
        ctx.state.vasSelection = evtData.vasSelection*/
        setTimeout(() => 
            ctx.setState({
                appointmentSelectedDate: evtData.selectedDate,
                appointmentSelectedSlot: evtData.selectedSlot,
                vasSelection: evtData.vasSelection,
            })
        );
        
    }
};

const isAjaxCallRequired= (action) => {
    const name = get(action, 'name', '');
    return name && (name  === 'VASMP_APPOINTMENT' || name === 'SHOPACTIONS' || name === 'VASMP_VEHICLE_SELECTION' || name === 'VAS_CONFIRM_VEHICLE');
};

const handleOperationWithUrl = (action, ctx, callback) => {
    updateInputParams(action, ctx);
    handleActionWithUrl(action, callback, ctx);
};

const handleAjaxResponse = (res, err, action, ctx) => {
    if (err) {
        handleAjaxError(err, action, ctx);
    } else {
        handleAjaxSuccess(res, action, ctx);
    }
    // ctx.setState({isBusy: false, loadingScreen: false, processingActionName: ''});
};

const getShopactionsCsrfToken = (action, ctx) => {
    if (action && action.clientPresentationMetadata) {  
        const global = get(ctx, 'input.options.global', {});
        const isMobile = get(global, 'isMobile', false);
        const csrfTokenList = isMobile ?  get(global, 'csrfTokens', {}) : get(global, 'csrfTokenList', {});
        if (csrfTokenList && action.clientPresentationMetadata.csrfApp && action.clientPresentationMetadata.csrfCommand) {
            return get(csrfTokenList, `${action.clientPresentationMetadata.csrfApp}.${action.clientPresentationMetadata.csrfCommand}`, "");
        }
    }
    return "";
};

const setScreenDefaultFocus = (targetElId, isInstallerScreen) => {
    if (targetElId) {
        const targetEl = document.getElementById(targetElId);
        let elemtToFocus = null;
        if (targetEl) {
            const focusElementQuery = isInstallerScreen ? ".whats-included .whats-included__title": ".appointment .appointment__date .section .section__title > span:first-child";
            if (targetEl.shadowRoot) {
                elemtToFocus = targetEl.shadowRoot.querySelector(focusElementQuery);
            } else {
                // Fallback if shadowDOM not available
                elemtToFocus = document.querySelector(focusElementQuery);
            } 
        }
        if (elemtToFocus) {
            elemtToFocus.focus();
        }
    }
};

const refreshKeyboardTrap = (channelId) => {
  pubsub.channel(channelId).emit('VAS_SCREEN_CONTROL_REFRESH');
}

const handleShopActionsFallbackAction = (action, ctx) => {
    const name = get(action, 'name', '');
    doRedirection(get(action, 'params.defaultActionUrl', ''), ctx.trksid, ctx.vasContainerElem, ctx, name);
};

const handleAppointmentFallbackAction = (action, ctx) => {
    if (ctx.state.isSpoke) {
        handleDefaultOperationWithoutUrl(action, ctx);
    } else {
        const name = get(action, 'name', ''); 
        const redirectionUrl = get(action,'params.defaultActionUrl', '')
        doRedirection(redirectionUrl, ctx.trksid, ctx.vasContainerElem, ctx, name);
    }
};

/**
 * In case of 500 or Timeout error
 */
const handleAjaxError = (err, action, ctx) => {
    const name = get(action, 'name', '');
    if (name) {
        if (name === 'VASMP_APPOINTMENT' || name === 'VASMP_VEHICLE_SELECTION' || name === 'VASMP_VEHICLE_SELECTION' || name === 'VAS_CONFIRM_VEHICLE') {
            handleAppointmentFallbackAction(action, ctx);
        } else if (name === 'SHOPACTIONS') {
            handleShopActionsFallbackAction(action, ctx);
        }
    } else {
        handleDefaultOperationWithoutUrl(action, ctx);
    }
};

const handleAppointmentSuccessResponse = (res, action, ctx) => {
    const appointmentModule = get(res, 'VAS_APPOINTMENT_SCHEDULE', {});
    if (appointmentModule.__isEmpty) { // 200 response with empty response
        if (ctx.state.isSpoke) {
            handleDefaultOperationWithoutUrl(action, ctx);
        } else {
            doRedirection(get(action,'params.defaultActionUrl', ''), ctx.trksid, ctx.vasContainerElem);
        }
    } else { // Success case
        const updatedAppointmentStates = ctx.getUpdatedAppointmentState(res);
        console.debug(`Reached before setting updatedAppointmentStates`);
        ctx.setState(updatedAppointmentStates);
        console.debug(`Reached after setting updatedAppointmentStates`);
        setTimeout(
            () => {
                refreshKeyboardTrap(ctx.channelId);
                setScreenDefaultFocus(ctx.targetElId, false);
            },
            100
        );
    }
};

const handleVehicleSuccessResponse = (res, action, ctx) => {
    const data = res || {};
    const vehicleModule = data.ADD_VEHICLE_SELECTION_DIALOG || data.GARAGE_SELECTION || {};

    if (Object.keys(vehicleModule).length === 0) { // 200 response with empty response or vehicle save response
        if(ctx.state.isSpoke) {
            handleDefaultOperationWithoutUrl(action, ctx, res) 
        } else {
            doRedirection(get(action,'params.defaultActionUrl', ''), ctx.trksid, ctx.vasContainerElem);        
        }
    } else { // Success case
        const updatedVehicleStates = ctx.getUpdatedVehicleState(res);
        console.debug(`Reached before setting updatedVehicleStates`);
        ctx.setState(updatedVehicleStates);
        console.debug(`Reached after setting updatedVehicleStates`);
        setTimeout(
            () => {
                refreshKeyboardTrap(ctx.channelId);
                setScreenDefaultFocus(ctx.targetElId, false);
            },
            100
        );
    }
};

const handleShopActionSuccessResponse = (res, action, ctx) => {
    const destinationUrl = get(res, 'meta.screenFlowDestination.URL', ''); // Successful case
    if (destinationUrl) {
        const name = get(action, 'name', '');
        doRedirection(destinationUrl, ctx.trksid, ctx.vasContainerElem, ctx, name);
    }
    return;
};

/**
 * When status code is 200
 */
const handleAjaxSuccess = (res, action, ctx) => {
    console.debug(`Reached handleAjaxSuccess`);
    const name = get(action, 'name', '');
    console.debug(`handleAjaxSuccess: name = ${name}`);
    if (name) {
        if (name === 'VASMP_APPOINTMENT') {
            handleAppointmentSuccessResponse(res, action, ctx);
            ctx.setState({isBusy: false, loadingScreen: false, processingActionName: ''});
        } else if(name === 'VASMP_VEHICLE_SELECTION' || name === 'VAS_CONFIRM_VEHICLE') {
            handleVehicleSuccessResponse(res, action, ctx);
            if (name === 'VASMP_VEHICLE_SELECTION') {
                ctx.setState({isBusy: false, loadingScreen: false, processingActionName: ''});
            }
        } else if(name === 'SHOPACTIONS') {
            handleShopActionSuccessResponse(res, action, ctx);
        } 
    }
};

const getVehicleParams = (component, selectedVehicle)=> {
    const vehicleDataParams = Object.assign({}, get(component, 'state.vehicleModules.VAS_DATA.dataItems.0', {}));

    if(vehicleDataParams.serviceType) {
        vehicleDataParams.vehicle = selectedVehicle.vehicle;
        vehicleDataParams.catalogDetails = selectedVehicle.catalogDetails;
        return [vehicleDataParams];
    }
}

module.exports = {
    dialogDismiss: dialogDismiss,
    handleCtaClick: handleCtaClick,
    getAppointmentState: getAppointmentState,
    updateState: updateState,
    getInstallerUrl: getInstallerUrl,
    mapConfig: mapConfig,
    track: track,
    getSelectedParams: getSelectedParams,
    preHandleOperationWithUrl: preHandleOperationWithUrl,
    updateInputParams: updateInputParams,
    isActionHandleByName: isActionHandleByName,
    updateAppointmentState: updateAppointmentState,
    isAjaxCallRequired: isAjaxCallRequired,
    handleOperationWithUrl: handleOperationWithUrl,
    handleOperationWithNameWithoutUrl: handleOperationWithNameWithoutUrl,
    handleDefaultOperationWithoutUrl: handleDefaultOperationWithoutUrl,
    getBase64EncodedString: getBase64EncodedString,
    handleAjaxResponse: handleAjaxResponse,
    setScreenDefaultFocus: setScreenDefaultFocus,
    refreshKeyboardTrap: refreshKeyboardTrap,
    handleAppointmentSuccessResponse: handleAppointmentSuccessResponse,
    handleShopActionSuccessResponse: handleShopActionSuccessResponse,
    handleAjaxSuccess: handleAjaxSuccess,
    removeUrlSid: removeUrlSid,
    handleAppointmentFallbackAction: handleAppointmentFallbackAction,
    handleShopActionsFallbackAction: handleShopActionsFallbackAction,
    checkOnDateTimeTrackingProperty: checkOnDateTimeTrackingProperty,
    getVehicleState: getVehicleState,
    handleVehicleSuccessResponse: handleVehicleSuccessResponse,
    getVehicleParams: getVehicleParams,
    getDefaultZipcode: getDefaultZipcode,
    getDefaultShipToCountry: getDefaultShipToCountry
}
