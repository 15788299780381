/* eslint-disable no-console */
const {get} = require('@ebay/retriever');
const { installerSort } = require('../../utils/sort');
const {
    dialogDismiss,
    mapConfig,
    updateState,
    getInstallerUrl,
    track,
    getAppointmentState,
    getVehicleState,
    isActionHandleByName,
    updateAppointmentState,
    isAjaxCallRequired,
    handleOperationWithUrl,
    handleOperationWithNameWithoutUrl,
    handleDefaultOperationWithoutUrl,
    handleAjaxResponse,
    checkOnDateTimeTrackingProperty
} = require('./../helper');

module.exports = class {
    onCreate(input) {
        input.mapConfig = mapConfig();
        this.channelId = input.channelId || '';
        // Addded this to support _sp tag
        this.trksid = get(input, 'modelConfig.trksid', '');
        this.vasContainerElem = null;
        this.isMobile = get(input, 'options.global.isMobile', false);
        this.targetElId = get(input, 'target', '');
        this.state = updateState(input);
        this.updateCTAThisContext = this.updateCTA.bind(this);
    }
    onMount() {
        this.vasContainerElem = document.getElementById('vas-container');
        window.addEventListener('pageshow', this.updateCTAThisContext);
    }

    updateCTA(event) {
        // In some browsers, back button click shows opened layer in VI page.
        // In order to show enabled CTA in that case, Enabling the CTA when the page is loaded with layer
        if (event.persisted) {
            if (this && this.setState) {
                this.setState({isBusy: false});
            }
        }    
    }

    dialogClose(isSpoke, closeAction) {
        track(this.channelId, get(closeAction, 'trackingList', []));
        dialogDismiss(this.channelId, isSpoke);
    }

    handleAppointmentChange(evtData) {
        updateAppointmentState(evtData, this);
    }

    buttonClick(action) {
        const type = get(action, 'type', '');
        const name = get(action, 'name', '');
        const URL = get(action, 'URL', '');
        let trackingList = get(action, 'trackingList', []);
        const dateTimeValue = `${this.state.appointmentSelectedDate},${this.state.appointmentSelectedSlot}`;
        // Do tracking for Button clicks
        trackingList = checkOnDateTimeTrackingProperty(trackingList, action, 'VAS_FE_SELECTED_APPT_SLOTS', dateTimeValue);
        track(this.channelId, trackingList);
        if (!this.state.isBusy) {
            if (type === 'OPERATION') {
                // Update Loading stae for CTA action click
                this.setState({
                    isBusy: true,
                    processingActionName: name
                });
                if (URL && isAjaxCallRequired(action)) { // Confirm Installer & Add Date & time CTA
                    handleOperationWithUrl(action, this, handleAjaxResponse.bind(this));
                } else if (isActionHandleByName(action)) { // Change Installer CTA
                    handleOperationWithNameWithoutUrl(action, this);
                    this.setState({isBusy: false, name: ''});
                } else { // No Thanks CTA or Confirm Installer CTA (spoke) takes user back to VI page
                    handleDefaultOperationWithoutUrl(action, this);
                    this.setState({isBusy: false, name: ''});
                }
            }
        }
    }

    getUpdatedAppointmentState(res) {
        return getAppointmentState({model: res, channelId: this.channelId})
    }

    getUpdatedVehicleState(res) {
        return getVehicleState({ model: res, channelId: this.channelId })
    }

    handleInstallerClick(installer) {
        if(installer.hasOwnProperty('paramValue')) {
            const vasForm = this.state.installerList;

            vasForm.forEach(i => (i.selected = i.paramValue === installer.paramValue));
            this.state.installerList = [...this.state.installerList];
            this.state.selectedInstaller = installer;
        }
    }

    handleValueChange(action, { name, radius, postalCode}) {
        if(radius) {
            this.state.radius = radius;
        }

        if(postalCode) {
            this.state.postalCode = postalCode;
        }

        if (name === 'keyDown' || radius) {
            this.getInstallers(action);
        }
    }

    getInstallers(action) {
        const {
            radius,
            postalCode,
        } = this.state;
        const model = get(this, 'input.model', {});
        const errorMessage = model.VAS_STATUS_MESSAGE || {};
        const csrfToken = get(this, 'input.options.global.csrfTokenList.vasfe.vasApi', '');
        const installerUrl = getInstallerUrl(action, { radius, postalCode, model, csrfToken });

        // emit pulsar click tracking for finder submit button
        track(this.channelId, action.trackingList);

        if (installerUrl && radius && postalCode) {
            this.state.showLoading = true;
            this.state.installerList = [];
            this.state.selectedInstaller = null;

            fetch(installerUrl, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                this.state.showNotice = !response.ok;

                return response.json();
            }).then(response => {
                const statusMessage = response.VAS_STATUS_MESSAGE || {};
                const messageType = get(statusMessage, 'message.messageType', '');
                const vasDetails = response.VAS_DETAILS || {};
                const installerList = vasDetails.vasForm || [];
                const sortComponet = this.getComponent('sort');

                this.state.installerList = installerList;
                this.state.selectedInstaller = installerList[0];
                this.state.mapCenterIcon = vasDetails.mapCenterIcon || {};
                this.state.sortV2 =  vasDetails.sortV2;   // - update sort component

                const installerScreenCTA = get(response, 'VAS_CTA', {});
                if (!installerScreenCTA.__isEmpty) {
                    this.state.installerScreenCTA = installerScreenCTA;
                }

                if(sortComponet) {
                    sortComponet.state.value = '';
                }

                if (vasDetails.hasOwnProperty('vasForm') || messageType === 'INFO') {
                    this.state.showNotice = false;

                    if (installerList.length === 0) {
                        this.state.showNotice = true;
                        this.state.statusMessage = statusMessage;
                    }
                } else {
                    this.state.showNotice = true;
                    this.state.statusMessage = errorMessage;
                }
            }).catch(() => {
                this.state.showNotice = true;
                this.state.statusMessage = errorMessage;
            }).finally(() => {
                this.state.showLoading = false;
            });
        }
    }

    handleSort({ sortBy }) {
        // sortby selected value
        this.state.installerList = this.state.installerList.slice().sort(installerSort[sortBy] || installerSort.DEFAULT);
        this.state.selectedInstaller = this.state.installerList[0];
    }

    doTracking(channelId, trackingList) {
        if (channelId && trackingList.length > 0) {
            track(channelId, trackingList);
        }
    }

    handleVehicleChange(data) {
        setTimeout(() => (this.state.selectedVehicle = data));
    }
};
